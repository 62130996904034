.arrow-collapsible {
  color: gray;
  font-size: 0.8rem;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in;
}

.Collapsible .Collapsible__trigger.is-open .arrow-collapsible {
  transform: rotate(180deg);
}

.driver-reservation {
  font-size: 0.8rem;
  color: black;
}

.table {
  font-size: 0.8rem;
}

.table-container {
  overflow-x: auto;
}

.disabled {
  background-color: #eeeeee;
  cursor: pointer;
}

.unlimited {
	cursor: pointer;
	color: #777777;
}

.table-container td {
  text-align: center;
}

.unlimited.unchecked {
  background-color: #eeeeee;
}

.input-number {
  border: none;
  text-align: center;
}

.input-number:focus {
  outline: none;
  border-bottom: 1px solid  black;
}